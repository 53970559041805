import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [massage, setMassage] = useState('')

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error('Please select a file');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('image', file);

      await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      setMassage('Image uploaded successfully');
      console.log('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error.message);
    }
  };

  return (
    <div className="container mt-5">
      <h3>File Upload</h3>
      <h2 style={{color:"green"}}>{massage}</h2>
      <div className="mb-3">
        <input className="form-control" type="file" onChange={handleFileChange} />
      </div>
      <button className="btn btn-primary" onClick={handleUpload}>
        Upload
      </button>
    </div>
  );
};

export default FileUpload;
